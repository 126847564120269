.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 14px;
  padding: 20px 12px;
  font-family: Arial, Helvetica, sans-serif;
}

h1 {
  font-size: 20px;
  font-weight: bold;
}

.question {
  margin-bottom: 4px;
  padding-bottom: 0;
}

.stars {
  margin-bottom: 12px;
}

.star {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-right: 8px;
  transition: all 0.2s ease-out;
  transform: translateY(0) scale(1);
}

.star:hover {
  cursor: pointer;
  transform: translateY(-4px) scale(1.1);
}

textarea {
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  padding: 12px;
}

.submit {
  margin-top: 8px;
  border-radius: 8px;
  border: 0;
  background-color: #50b848;
  padding: 8px 12px;
  color: white;
}

@media (max-width: 600px) {
  .logo {
    width: 241px;
    height: 32px;
  }
}
